import * as _froalaEditor2 from "froala-editor";

var _froalaEditor = "default" in _froalaEditor2 ? _froalaEditor2.default : _froalaEditor2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * froala_editor v4.0.15 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2022 Froala Labs
 */
!function (e, t) {
  t(_froalaEditor);
}(exports, function (Me) {
  "use strict";

  function Ke(e) {
    return (Ke = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    })(e);
  }

  Me = Me && Me.hasOwnProperty("default") ? Me["default"] : Me, Object.assign(Me.POPUP_TEMPLATES, {
    "image.insert": "[_BUTTONS_][_UPLOAD_LAYER_][_BY_URL_LAYER_][_PROGRESS_BAR_]",
    "image.edit": "[_BUTTONS_]",
    "image.alt": "[_BUTTONS_][_ALT_LAYER_]",
    "image.size": "[_BUTTONS_][_SIZE_LAYER_]"
  }), Object.assign(Me.DEFAULTS, {
    imageInsertButtons: ["imageBack", "|", "imageUpload", "imageByURL"],
    imageEditButtons: ["imageReplace", "imageAlign", "imageCaption", "imageRemove", "imageLink", "linkOpen", "linkEdit", "linkRemove", "-", "imageDisplay", "imageStyle", "imageAlt", "imageSize"],
    imageAltButtons: ["imageBack", "|"],
    imageSizeButtons: ["imageBack", "|"],
    imageUpload: !0,
    imageUploadURL: null,
    imageCORSProxy: "https://cors-anywhere.froala.com",
    imageUploadRemoteUrls: !0,
    imageUploadParam: "file",
    imageUploadParams: {},
    imageUploadToS3: !1,
    imageUploadToAzure: !1,
    imageUploadMethod: "POST",
    imageMaxSize: 10485760,
    imageAllowedTypes: ["jpeg", "jpg", "png", "gif", "webp"],
    imageResize: !0,
    imageResizeWithPercent: !1,
    imageRoundPercent: !1,
    imageDefaultWidth: 300,
    imageDefaultAlign: "center",
    imageDefaultDisplay: "block",
    imageSplitHTML: !1,
    imageStyles: {
      "fr-rounded": "Rounded",
      "fr-bordered": "Bordered",
      "fr-shadow": "Shadow"
    },
    imageMove: !0,
    imageMultipleStyles: !0,
    imageTextNear: !0,
    imagePaste: !0,
    imagePasteProcess: !1,
    imageMinWidth: 16,
    imageOutputSize: !1,
    imageDefaultMargin: 5,
    imageAddNewLine: !1
  }), Me.IMAGE_ALLOW_REGEX = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i, Me.PLUGINS.image = function (A) {
    var C,
        l,
        p,
        g,
        s,
        a,
        S = A.$,
        E = "https://i.froala.com/upload",
        t = !1,
        i = 1,
        c = 2,
        d = 3,
        m = 4,
        R = 5,
        U = 6,
        r = {};

    function f() {
      var e = A.popups.get("image.insert").find(".fr-image-by-url-layer input");
      e.val(""), C && e.val(C.attr("src")), e.trigger("change");
    }

    function o(e) {
      return window.pageYOffset + e.get(0).getBoundingClientRect().top;
    }

    function n() {
      var e = A.popups.get("image.edit");

      if (e || (e = P()), e) {
        var t = C;
        A.popups.setContainer("image.edit", A.$sc), A.popups.refresh("image.edit");
        var a = t.offset().left + t.outerWidth() / 2,
            i = t && o(t),
            r = A.$wp && o(A.$wp);
        r || (r = i);
        var n = 0;
        A.opts.iframe ? ((n = A.$wp.scrollTop() + e.outerHeight()) < t.get(0).getBoundingClientRect().top && (n = t.get(0).getBoundingClientRect().top), n > t.get(0).getBoundingClientRect().top + t.height() && (n = t.get(0).getBoundingClientRect().top + t.height())) : n = r < i ? i : A.$wp && i + t.height() < r + A.$wp.height() ? i + t.height() : r + e.outerHeight(), C.hasClass("fr-uploading") ? k() : A.popups.show("image.edit", a, n, 1, !0);
      }
    }

    function u() {
      N();
    }

    function h(e) {
      0 < e.parents(".fr-img-caption").length && (e = e.parents(".fr-img-caption").first());
      var t = e.hasClass("fr-dib") ? "block" : e.hasClass("fr-dii") ? "inline" : null,
          a = e.hasClass("fr-fil") ? "left" : e.hasClass("fr-fir") ? "right" : ve(e);
      he(e, t, a), e.removeClass("fr-dib fr-dii fr-fir fr-fil");
    }

    function v() {
      for (var e, t = "IMG" == A.el.tagName ? [A.el] : A.el.querySelectorAll("img"), a = 0; a < t.length; a++) {
        var i = S(t[a]);
        !A.opts.htmlUntouched && A.opts.useClasses ? ((A.opts.imageDefaultAlign || A.opts.imageDefaultDisplay) && (0 < (e = i).parents(".fr-img-caption").length && (e = e.parents(".fr-img-caption").first()), e.hasClass("fr-dii") || e.hasClass("fr-dib") || (e.addClass("fr-fi".concat(ve(e)[0])), e.addClass("fr-di".concat(be(e)[0])), e.css("margin", ""), e.css("float", ""), e.css("display", ""), e.css("z-index", ""), e.css("position", ""), e.css("overflow", ""), e.css("vertical-align", ""))), A.opts.imageTextNear || (0 < i.parents(".fr-img-caption").length ? i.parents(".fr-img-caption").first().removeClass("fr-dii").addClass("fr-dib") : i.removeClass("fr-dii").addClass("fr-dib"))) : A.opts.htmlUntouched || A.opts.useClasses || (A.opts.imageDefaultAlign || A.opts.imageDefaultDisplay) && h(i), A.opts.iframe && i.on("load", A.size.syncIframe);
      }
    }

    function b(e) {
      void 0 === e && (e = !0);
      var t,
          a = Array.prototype.slice.call(A.el.querySelectorAll("img")),
          i = [];

      for (t = 0; t < a.length; t++) if (i.push(a[t].getAttribute("src")), S(a[t]).toggleClass("fr-draggable", A.opts.imageMove), "" === a[t].getAttribute("class") && a[t].removeAttribute("class"), "" === a[t].getAttribute("style") && a[t].removeAttribute("style"), a[t].parentNode && a[t].parentNode.parentNode && A.node.hasClass(a[t].parentNode.parentNode, "fr-img-caption")) {
        var r = a[t].parentNode.parentNode;
        A.browser.mozilla || r.setAttribute("contenteditable", !1), r.setAttribute("draggable", !1), r.classList.add("fr-draggable");
        var n = a[t].nextSibling;
        n && !A.browser.mozilla && n.setAttribute("contenteditable", !0);
      }

      if (s) for (t = 0; t < s.length; t++) i.indexOf(s[t].getAttribute("src")) < 0 && A.events.trigger("image.removed", [S(s[t])]);

      if (s && e) {
        var o = [];

        for (t = 0; t < s.length; t++) o.push(s[t].getAttribute("src"));

        for (t = 0; t < a.length; t++) o.indexOf(a[t].getAttribute("src")) < 0 && A.events.trigger("image.loaded", [S(a[t])]);
      }

      s = a;
    }

    function D() {
      if (l || function o() {
        var e;
        A.shared.$image_resizer ? (l = A.shared.$image_resizer, g = A.shared.$img_overlay, A.events.on("destroy", function () {
          S("body").first().append(l.removeClass("fr-active"));
        }, !0)) : (A.shared.$image_resizer = S(document.createElement("div")).attr("class", "fr-image-resizer"), l = A.shared.$image_resizer, A.events.$on(l, "mousedown", function (e) {
          e.stopPropagation();
        }, !0), A.opts.imageResize && (l.append(y("nw") + y("ne") + y("sw") + y("se")), A.shared.$img_overlay = S(document.createElement("div")).attr("class", "fr-image-overlay"), g = A.shared.$img_overlay, e = l.get(0).ownerDocument, S(e).find("body").first().append(g)));
        A.events.on("shared.destroy", function () {
          l.html("").removeData().remove(), l = null, A.opts.imageResize && (g.remove(), g = null);
        }, !0), A.helpers.isMobile() || A.events.$on(S(A.o_win), "resize", function () {
          C && !C.hasClass("fr-uploading") ? ce(!0) : C && (D(), ye(), k(!1));
        });

        if (A.opts.imageResize) {
          e = l.get(0).ownerDocument, A.events.$on(l, A._mousedown, ".fr-handler", x), A.events.$on(S(e), A._mousemove, T), A.events.$on(S(e.defaultView || e.parentWindow), A._mouseup, $), A.events.$on(g, "mouseleave", $);
          var i = 1,
              r = null,
              n = 0;
          A.events.on("keydown", function (e) {
            if (C) {
              var t = -1 != navigator.userAgent.indexOf("Mac OS X") ? e.metaKey : e.ctrlKey,
                  a = e.which;
              (a !== r || 200 < e.timeStamp - n) && (i = 1), (a == Me.KEYCODE.EQUALS || A.browser.mozilla && a == Me.KEYCODE.FF_EQUALS) && t && !e.altKey ? i = te.call(this || _global, e, 1, 1, i) : (a == Me.KEYCODE.HYPHEN || A.browser.mozilla && a == Me.KEYCODE.FF_HYPHEN) && t && !e.altKey ? i = te.call(this || _global, e, 2, -1, i) : A.keys.ctrlKey(e) || a != Me.KEYCODE.ENTER || (C.before("<br>"), z(C)), r = a, n = e.timeStamp;
            }
          }, !0), A.events.on("keyup", function () {
            i = 1;
          });
        }
      }(), !C) return !1;
      var e = A.$wp || A.$sc;
      e.append(l), l.data("instance", A);
      var t = e.scrollTop() - ("static" != e.css("position") ? e.offset().top : 0),
          a = e.scrollLeft() - ("static" != e.css("position") ? e.offset().left : 0);
      a -= A.helpers.getPX(e.css("border-left-width")), t -= A.helpers.getPX(e.css("border-top-width")), A.$el.is("img") && A.$sc.is("body") && (a = t = 0);
      var i = Ce();
      Se() && (i = i.find(".fr-img-wrap"));
      var r = 0,
          n = 0;
      A.opts.iframe && (r = A.helpers.getPX(A.$wp.find(".fr-iframe").css("padding-top")), n = A.helpers.getPX(A.$wp.find(".fr-iframe").css("padding-left"))), l.css("top", (A.opts.iframe ? i.offset().top + r : i.offset().top + t) - 1).css("left", (A.opts.iframe ? i.offset().left + n : i.offset().left + a) - 1).css("width", i.get(0).getBoundingClientRect().width).css("height", i.get(0).getBoundingClientRect().height).addClass("fr-active");
    }

    function y(e) {
      return "<div class=\"fr-handler fr-h".concat(e, "\"></div>");
    }

    function w(e) {
      Se() && C.parents(".fr-img-caption").css("width", e), C.css("width", e);
    }

    function x(e) {
      if (!A.core.sameInstance(l)) return !0;
      if (e.preventDefault(), e.stopPropagation(), A.$el.find("img.fr-error").left) return !1;
      A.undo.canDo() || A.undo.saveStep();
      var t = e.pageX || e.originalEvent.touches[0].pageX;

      if ("mousedown" == e.type) {
        var a = A.$oel.get(0).ownerDocument,
            i = a.defaultView || a.parentWindow,
            r = !1;

        try {
          r = i.location != i.parent.location && !(i.$ && i.$.FE);
        } catch (s) {}

        r && i.frameElement && (t += A.helpers.getPX(S(i.frameElement).offset().left) + i.frameElement.clientLeft);
      }

      (p = S(this || _global)).data("start-x", t), p.data("start-width", C.width()), p.data("start-height", C.height());
      var n = C.width();

      if (A.opts.imageResizeWithPercent) {
        var o = C.parentsUntil(A.$el, A.html.blockTagsQuery()).get(0) || A.el;
        n = (n / S(o).outerWidth() * 100).toFixed(2) + "%";
      }

      w(n), g.show(), A.popups.hideAll(), ue();
    }

    function T(e) {
      if (!A.core.sameInstance(l)) return !0;
      var t;

      if (p && C) {
        if (e.preventDefault(), A.$el.find("img.fr-error").left) return !1;
        var a = e.pageX || (e.originalEvent.touches ? e.originalEvent.touches[0].pageX : null);
        if (!a) return !1;
        var i = a - p.data("start-x"),
            r = p.data("start-width");

        if ((p.hasClass("fr-hnw") || p.hasClass("fr-hsw")) && (i = 0 - i), A.opts.imageResizeWithPercent) {
          var n = C.parentsUntil(A.$el, A.html.blockTagsQuery()).get(0) || A.el;
          r = ((r + i) / S(n).outerWidth() * 100).toFixed(2), A.opts.imageRoundPercent && (r = Math.round(r)), w("".concat(r, "%")), (t = Se() ? (A.helpers.getPX(C.parents(".fr-img-caption").css("width")) / S(n).outerWidth() * 100).toFixed(2) : (A.helpers.getPX(C.css("width")) / S(n).outerWidth() * 100).toFixed(2)) === r || A.opts.imageRoundPercent || w("".concat(t, "%")), C.css("height", "").removeAttr("height");
        } else r + i >= A.opts.imageMinWidth && (w(r + i), t = Se() ? A.helpers.getPX(C.parents(".fr-img-caption").css("width")) : A.helpers.getPX(C.css("width"))), t !== r + i && w(t), ((C.attr("style") || "").match(/(^height:)|(; *height:)/) || C.attr("height")) && (C.css("height", p.data("start-height") * C.width() / p.data("start-width")), C.removeAttr("height"));

        D(), A.events.trigger("image.resize", [Ae()]);
      }
    }

    function $(e) {
      if (!A.core.sameInstance(l)) return !0;

      if (p && C) {
        if (e && e.stopPropagation(), A.$el.find("img.fr-error").left) return !1;
        p = null, g.hide(), D(), n(), A.undo.saveStep(), A.events.trigger("image.resizeEnd", [Ae()]);
      } else l.removeClass("fr-active");
    }

    function I(e, t, a) {
      A.edit.on(), C && C.addClass("fr-error"), r[e] ? O(A.language.translate(r[e])) : O(A.language.translate("Something went wrong. Please try again.")), !C && a && ae(a), A.events.trigger("image.error", [{
        code: e,
        message: r[e]
      }, t, a]);
    }

    function P(e) {
      if (e) return A.$wp && A.events.$on(A.$wp, "scroll.image-edit", function () {
        C && A.popups.isVisible("image.edit") && (A.events.disableBlur(), n());
      }), !0;
      var t = "";

      if (0 < A.opts.imageEditButtons.length) {
        var a = {
          buttons: t += "<div class=\"fr-buttons\">\n        ".concat(A.button.buildList(A.opts.imageEditButtons), "\n        </div>")
        };
        return A.popups.create("image.edit", a);
      }

      return !1;
    }

    function k(e) {
      var t = A.popups.get("image.insert");

      if (t || (t = X()), t.find(".fr-layer.fr-active").removeClass("fr-active").addClass("fr-pactive"), t.find(".fr-image-progress-bar-layer").addClass("fr-active"), t.find(".fr-buttons").hide(), C) {
        var a = Ce();
        A.popups.setContainer("image.insert", A.$sc);
        var i = a.offset().left,
            r = a.offset().top + a.height();
        A.popups.show("image.insert", i, r, a.outerHeight());
      }

      void 0 === e && B(A.language.translate("Uploading"), 0);
    }

    function N(e) {
      var t = A.popups.get("image.insert");

      if (t && (t.find(".fr-layer.fr-pactive").addClass("fr-active").removeClass("fr-pactive"), t.find(".fr-image-progress-bar-layer").removeClass("fr-active"), t.find(".fr-buttons").show(), e || A.$el.find("img.fr-error").length)) {
        if (A.events.focus(), A.$el.find("img.fr-error").length && (A.$el.find("img.fr-error").remove(), A.undo.saveStep(), A.undo.run(), A.undo.dropRedo()), !A.$wp && C) {
          var a = C;
          ce(!0), A.selection.setAfter(a.get(0)), A.selection.restore();
        }

        A.popups.hide("image.insert");
      }
    }

    function B(e, t) {
      var a = A.popups.get("image.insert");

      if (a) {
        var i = a.find(".fr-image-progress-bar-layer");
        i.find("h3").text(e + (t ? " ".concat(t, "%") : "")), i.removeClass("fr-error"), t ? (i.find("div").removeClass("fr-indeterminate"), i.find("div > span").css("width", "".concat(t, "%"))) : i.find("div").addClass("fr-indeterminate");
      }
    }

    function O(e) {
      k();
      var t = A.popups.get("image.insert").find(".fr-image-progress-bar-layer");
      t.addClass("fr-error");
      var a = t.find("h3");
      a.text(e), A.events.disableBlur(), a.focus();
    }

    function z(e) {
      fe.call(e.get(0));
    }

    function L() {
      var e = S(this || _global);
      A.popups.hide("image.insert"), e.removeClass("fr-uploading"), e.next().is("br") && e.next().remove(), z(e), A.events.trigger("image.loaded", [e]);
    }

    function _(o, e, s, l, p) {
      l && "string" == typeof l && (l = A.$(l)), A.edit.off(), B(A.language.translate("Loading image")), e && (o = A.helpers.sanitizeURL(o));
      var t = new Image();
      t.onload = function () {
        var e, t;

        if (l) {
          A.undo.canDo() || l.hasClass("fr-uploading") || A.undo.saveStep();
          var a = l.data("fr-old-src");
          l.data("fr-image-pasted") && (a = null), A.$wp ? ((e = l.clone().removeData("fr-old-src").removeClass("fr-uploading").removeAttr("data-fr-image-pasted")).off("load"), a && l.attr("src", a), !A.opts.trackChangesEnabled || l[0].parentNode && "SPAN" === l[0].parentNode.tagName && l[0].parentNode.hasAttribute("data-tracking") || A.track_changes.replaceSpecialItem(l), l.replaceWith(e)) : e = l;

          for (var i = e.get(0).attributes, r = 0; r < i.length; r++) {
            var n = i[r];
            0 === n.nodeName.indexOf("data-") ? e.removeAttr(n.nodeName) : s && s.hasOwnProperty(n.nodeName) && e.removeAttr(n.nodeName);
          }

          if (void 0 !== s) for (t in s) s.hasOwnProperty(t) && "link" != t && e.attr("".concat(t), s[t]);
          e.on("load", L), e.attr("src", o), A.edit.on(), b(!1), l[0].src != o && A.selection.restore(), A.undo.saveStep(), A.events.disableBlur(), A.$el.blur(), A.events.trigger(a ? "image.replaced" : "image.inserted", [e, p]);
        } else (e = W(o, s, L)) && (b(!1), A.undo.saveStep(), A.events.disableBlur(), A.$el.blur(), A.events.trigger("image.inserted", [e, p]));
      }, t.onerror = function () {
        I(i);
      }, k(A.language.translate("Loading image")), t.src = o;
    }

    function M(e, t, a) {
      B(A.language.translate("Loading image"));
      var i = (this || _global).status,
          r = (this || _global).response,
          n = (this || _global).responseXML,
          o = (this || _global).responseText;

      try {
        if (A.opts.imageUploadToS3 || A.opts.imageUploadToAzure) {
          if (201 == i) {
            var s;

            if (A.opts.imageUploadToAzure) {
              if (!1 === A.events.trigger("image.uploadedToAzure", [(this || _global).responseURL, a, r], !0)) return A.edit.on(), !1;
              s = t;
            } else s = function p(e) {
              try {
                var t = S(e).find("Location").text(),
                    a = S(e).find("Key").text();
                return !1 === A.events.trigger("image.uploadedToS3", [t, a, e], !0) ? (A.edit.on(), !1) : t;
              } catch (i) {
                return I(m, e), !1;
              }
            }(n);

            s && _(s, !1, [], e, r || n);
          } else I(m, r || n, e);
        } else if (200 <= i && i < 300) {
          var l = function g(e) {
            try {
              if (!1 === A.events.trigger("image.uploaded", [e], !0)) return A.edit.on(), !1;
              var t = JSON.parse(e);
              return t.link ? t : (I(c, e), !1);
            } catch (a) {
              return I(m, e), !1;
            }
          }(o);

          l && _(l.link, !1, l, e, r || o);
        } else I(d, r || o, e);
      } catch (f) {
        I(m, r || o, e);
      }
    }

    function K() {
      I(m, (this || _global).response || (this || _global).responseText || (this || _global).responseXML);
    }

    function Y(e) {
      if (e.lengthComputable) {
        var t = e.loaded / e.total * 100 | 0;
        B(A.language.translate("Uploading"), t);
      }
    }

    function W(e, t, a) {
      var i,
          r = S(document.createElement("img")).attr("src", e);
      if (t && void 0 !== t) for (i in t) t.hasOwnProperty(i) && "link" != i && (" data-".concat(i, "=\"").concat(t[i], "\""), r.attr("".concat(i), t[i]));
      var n = A.opts.imageDefaultWidth;
      n && "auto" != n && (n = A.opts.imageResizeWithPercent ? "100%" : "".concat(n, "px")), r.attr("style", n ? "width: ".concat(n, ";") : ""), he(r, A.opts.imageDefaultDisplay, A.opts.imageDefaultAlign), r.on("load", a), r.on("error", a), A.edit.on(), A.events.focus(!0), A.selection.restore(), A.undo.saveStep(), A.opts.imageSplitHTML ? A.markers.split() : A.markers.insert(), A.html.wrap();
      var o = A.$el.find(".fr-marker");
      if (o.length) (o.parent().is("hr") || o.parent().is("IMG")) && o.parent().after(o), A.node.isLastSibling(o) && o.parent().hasClass("fr-deletable") && o.insertAfter(o.parent()), o.replaceWith(r);else {
        if (A.opts.trackChangesEnabled) return N(!0), !1;
        A.$el.append(r);
      }
      return A.selection.clear(), r;
    }

    function G() {
      A.edit.on(), N(!0);
    }

    function H(e, t) {
      if (void 0 !== e && 0 < e.length) {
        if (!1 === A.events.trigger("image.beforeUpload", [e, t])) return !1;
        var a,
            i = e[0];
        if (!(null !== A.opts.imageUploadURL && A.opts.imageUploadURL != E || A.opts.imageUploadToS3 || A.opts.imageUploadToAzure)) return function y(r, n) {
          var o = new FileReader();
          o.onload = function () {
            var e = o.result;

            if (o.result.indexOf("svg+xml") < 0) {
              for (var t = atob(o.result.split(",")[1]), a = [], i = 0; i < t.length; i++) a.push(t.charCodeAt(i));

              e = window.URL.createObjectURL(new Blob([new Uint8Array(a)], {
                type: r.type
              })), n && n.data("fr-old-src", n.attr("src")), A.image.insert(e, !1, null, n);
            } else A.image.insert(e, null, null, A.image.get());
          }, k(), o.readAsDataURL(r);
        }(i, t || C), !1;
        if (i.name || (i.name = new Date().getTime() + "." + (i.type || "image/jpeg").replace(/image\//g, "")), i.size > A.opts.imageMaxSize) return I(R), !1;
        if (A.opts.imageAllowedTypes.indexOf(i.type.replace(/image\//g, "")) < 0) return I(U), !1;

        if (A.drag_support.formdata && (a = A.drag_support.formdata ? new FormData() : null), a) {
          var r;
          if (!1 !== A.opts.imageUploadToS3) for (r in a.append("key", A.opts.imageUploadToS3.keyStart + new Date().getTime() + "-" + (i.name || "untitled")), a.append("success_action_status", "201"), a.append("X-Requested-With", "xhr"), a.append("Content-Type", i.type), A.opts.imageUploadToS3.params) A.opts.imageUploadToS3.params.hasOwnProperty(r) && a.append(r, A.opts.imageUploadToS3.params[r]);

          for (r in A.opts.imageUploadParams) A.opts.imageUploadParams.hasOwnProperty(r) && a.append(r, A.opts.imageUploadParams[r]);

          a.append(A.opts.imageUploadParam, i, i.name);
          var n,
              o,
              s = A.opts.imageUploadURL,
              l = A.opts.imageUploadMethod;
          A.opts.imageUploadToS3 && (s = A.opts.imageUploadToS3.uploadURL ? A.opts.imageUploadToS3.uploadURL : "https://".concat(A.opts.imageUploadToS3.region, ".amazonaws.com/").concat(A.opts.imageUploadToS3.bucket)), A.opts.imageUploadToAzure && (n = s = A.opts.imageUploadToAzure.uploadURL ? "".concat(A.opts.imageUploadToAzure.uploadURL, "/").concat(i.name) : encodeURI("https://".concat(A.opts.imageUploadToAzure.account, ".blob.core.windows.net/").concat(A.opts.imageUploadToAzure.container, "/").concat(i.name)), A.opts.imageUploadToAzure.SASToken && (s += A.opts.imageUploadToAzure.SASToken), l = "PUT");
          var p = A.core.getXHR(s, l);

          if (A.opts.imageUploadToAzure) {
            var g = new Date().toUTCString();

            if (!A.opts.imageUploadToAzure.SASToken && A.opts.imageUploadToAzure.accessKey) {
              var f = A.opts.imageUploadToAzure.account,
                  c = A.opts.imageUploadToAzure.container;

              if (A.opts.imageUploadToAzure.uploadURL) {
                var d = A.opts.imageUploadToAzure.uploadURL.split("/");
                c = d.pop(), f = d.pop().split(".")[0];
              }

              var m = "x-ms-blob-type:BlockBlob\nx-ms-date:".concat(g, "\nx-ms-version:2019-07-07"),
                  u = encodeURI("/" + f + "/" + c + "/" + i.name),
                  h = l + "\n\n\n" + i.size + "\n\n" + i.type + "\n\n\n\n\n\n\n" + m + "\n" + u,
                  v = A.cryptoJSPlugin.cryptoJS.HmacSHA256(h, A.cryptoJSPlugin.cryptoJS.enc.Base64.parse(A.opts.imageUploadToAzure.accessKey)).toString(A.cryptoJSPlugin.cryptoJS.enc.Base64),
                  b = "SharedKey " + f + ":" + v;
              o = v, p.setRequestHeader("Authorization", b);
            }

            for (r in p.setRequestHeader("x-ms-version", "2019-07-07"), p.setRequestHeader("x-ms-date", g), p.setRequestHeader("Content-Type", i.type), p.setRequestHeader("x-ms-blob-type", "BlockBlob"), A.opts.imageUploadParams) A.opts.imageUploadParams.hasOwnProperty(r) && p.setRequestHeader(r, A.opts.imageUploadParams[r]);

            for (r in A.opts.imageUploadToAzure.params) A.opts.imageUploadToAzure.params.hasOwnProperty(r) && p.setRequestHeader(r, A.opts.imageUploadToAzure.params[r]);
          }

          !function w(t, a, i, r, n, o) {
            function s() {
              var e = S(this || _global);
              e.off("load"), e.addClass("fr-uploading"), e.next().is("br") && e.next().remove(), A.placeholder.refresh(), z(e), D(), k(), A.edit.off(), t.onload = function () {
                M.call(t, e, n, o);
              }, t.onerror = K, t.upload.onprogress = Y, t.onabort = G, S(e.off("abortUpload")).on("abortUpload", function () {
                4 != t.readyState && (t.abort(), r ? (r.attr("src", r.data("fr-old-src")), r.removeClass("fr-uploading")) : e.remove(), ce(!0));
              }), t.send(A.opts.imageUploadToAzure ? i : a);
            }

            var l = new FileReader();
            l.onload = function () {
              var e = l.result;

              if (l.result.indexOf("svg+xml") < 0) {
                for (var t = atob(l.result.split(",")[1]), a = [], i = 0; i < t.length; i++) a.push(t.charCodeAt(i));

                e = window.URL.createObjectURL(new Blob([new Uint8Array(a)], {
                  type: "image/jpeg"
                }));
              }

              r ? (r.on("load", s), r.on("error", function () {
                s(), S(this || _global).off("error");
              }), A.edit.on(), A.undo.saveStep(), r.data("fr-old-src", r.attr("src")), r.attr("src", e)) : W(e, null, s);
            }, l.readAsDataURL(i);
          }(p, a, i, t || C, n, o);
        }
      }
    }

    function F(e) {
      if (e.is("img") && 0 < e.parents(".fr-img-caption").length) return e.parents(".fr-img-caption");
    }

    function V(e) {
      var t = e.originalEvent.dataTransfer;

      if (t && t.files && t.files.length) {
        var a = t.files[0];

        if (a && a.type && -1 !== a.type.indexOf("image") && 0 <= A.opts.imageAllowedTypes.indexOf(a.type.replace(/image\//g, ""))) {
          if (!A.opts.imageUpload) return e.preventDefault(), e.stopPropagation(), !1;
          A.markers.remove(), A.markers.insertAtPoint(e.originalEvent), A.$el.find(".fr-marker").replaceWith(Me.MARKERS), 0 === A.$el.find(".fr-marker").length && A.selection.setAtEnd(A.el), A.popups.hideAll();
          var i = A.popups.get("image.insert");
          i || (i = X()), A.popups.setContainer("image.insert", A.$sc);
          var r = e.originalEvent.pageX,
              n = e.originalEvent.pageY;

          if (A.opts.iframe) {
            var o = A.helpers.getPX(A.$wp.find(".fr-iframe").css("padding-top")),
                s = A.helpers.getPX(A.$wp.find(".fr-iframe").css("padding-left"));
            n += A.$iframe.offset().top + o, r += A.$iframe.offset().left + s;
          }

          return A.popups.show("image.insert", r, n), k(), 0 <= A.opts.imageAllowedTypes.indexOf(a.type.replace(/image\//g, "")) ? (ce(!0), H(t.files)) : I(U), e.preventDefault(), e.stopPropagation(), !1;
        }
      }
    }

    function X(e) {
      if (e) return A.popups.onRefresh("image.insert", f), A.popups.onHide("image.insert", u), !0;
      var t,
          a,
          i = "";
      A.opts.imageUpload || -1 === A.opts.imageInsertButtons.indexOf("imageUpload") || A.opts.imageInsertButtons.splice(A.opts.imageInsertButtons.indexOf("imageUpload"), 1);
      var r = A.button.buildList(A.opts.imageInsertButtons);
      "" !== r && (i = "<div class=\"fr-buttons fr-tabs\">".concat(r, "</div>"));
      var n = A.opts.imageInsertButtons.indexOf("imageUpload"),
          o = A.opts.imageInsertButtons.indexOf("imageByURL"),
          s = "";
      0 <= n && (t = " fr-active", 0 <= o && o < n && (t = ""), s = "<div class=\"fr-image-upload-layer".concat(t, " fr-layer\" id=\"fr-image-upload-layer-").concat(A.id, "\"><strong>").concat(A.language.translate("Drop image"), "</strong><br>(").concat(A.language.translate("or click"), ")<div class=\"fr-form\"><input type=\"file\" accept=\"image/").concat(A.opts.imageAllowedTypes.join(", image/").toLowerCase(), "\" tabIndex=\"-1\" aria-labelledby=\"fr-image-upload-layer-").concat(A.id, "\" role=\"button\"></div></div>"));
      var l = "";
      0 <= o && (t = " fr-active", 0 <= n && n < o && (t = ""), l = "<div class=\"fr-image-by-url-layer".concat(t, " fr-layer\" id=\"fr-image-by-url-layer-").concat(A.id, "\"><div class=\"fr-input-line\"><input id=\"fr-image-by-url-layer-text-").concat(A.id, "\" type=\"text\" placeholder=\"http://\" tabIndex=\"1\" aria-required=\"true\"></div><div class=\"fr-action-buttons\"><button type=\"button\" class=\"fr-command fr-submit\" data-cmd=\"imageInsertByURL\" tabIndex=\"2\" role=\"button\">").concat(A.language.translate("Insert"), "</button></div></div>"));
      var p = {
        buttons: i,
        upload_layer: s,
        by_url_layer: l,
        progress_bar: "<div class=\"fr-image-progress-bar-layer fr-layer\"><h3 tabIndex=\"-1\" class=\"fr-message\">Uploading</h3><div class=\"fr-loader\"><span class=\"fr-progress\"></span></div><div class=\"fr-action-buttons\"><button type=\"button\" class=\"fr-command fr-dismiss\" data-cmd=\"imageDismissError\" tabIndex=\"2\" role=\"button\">OK</button></div></div>"
      };
      return 1 <= A.opts.imageInsertButtons.length && (a = A.popups.create("image.insert", p)), A.$wp && A.events.$on(A.$wp, "scroll", function () {
        C && A.popups.isVisible("image.insert") && ye();
      }), function g(i) {
        A.events.$on(i, "dragover dragenter", ".fr-image-upload-layer", function (e) {
          return S(this || _global).addClass("fr-drop"), (A.browser.msie || A.browser.edge) && e.preventDefault(), !1;
        }, !0), A.events.$on(i, "dragleave dragend", ".fr-image-upload-layer", function (e) {
          return S(this || _global).removeClass("fr-drop"), (A.browser.msie || A.browser.edge) && e.preventDefault(), !1;
        }, !0), A.events.$on(i, "drop", ".fr-image-upload-layer", function (e) {
          e.preventDefault(), e.stopPropagation(), S(this || _global).removeClass("fr-drop");
          var t = e.originalEvent.dataTransfer;

          if (t && t.files) {
            var a = i.data("instance") || A;
            a.events.disableBlur(), a.image.upload(t.files), a.events.enableBlur();
          }
        }, !0), A.helpers.isIOS() && A.events.$on(i, "touchstart", ".fr-image-upload-layer input[type=\"file\"]", function () {
          S(this || _global).trigger("click");
        }, !0), A.events.$on(i, "change", ".fr-image-upload-layer input[type=\"file\"]", function () {
          if ((this || _global).files) {
            var e = i.data("instance") || A;
            e.events.disableBlur(), i.find("input:focus").blur(), e.events.enableBlur(), e.image.upload((this || _global).files, C);
          }

          S(this || _global).val("");
        }, !0);
      }(a), a;
    }

    function q() {
      C && A.popups.get("image.alt").find("input").val(C.attr("alt") || "").trigger("change");
    }

    function j() {
      var e = A.popups.get("image.alt");
      e || (e = J()), N(), A.popups.refresh("image.alt"), A.popups.setContainer("image.alt", A.$sc);
      var t = Ce(),
          a = t.outerWidth() / 2;
      Se() && (t = t.find(".fr-img-wrap"), a = C.outerWidth() / 2);
      var i = t.offset().left + a,
          r = t.offset().top + t.outerHeight(),
          n = t.outerHeight();
      A.opts.iframe && Se() && (n = C ? C.outerHeight() : 0), A.popups.show("image.alt", i, r, n, !0);
    }

    function J(e) {
      if (e) return A.popups.onRefresh("image.alt", q), !0;
      var t = {
        buttons: "<div class=\"fr-buttons fr-tabs\">".concat(A.button.buildList(A.opts.imageAltButtons), "</div>"),
        alt_layer: "<div class=\"fr-image-alt-layer fr-layer fr-active\" id=\"fr-image-alt-layer-".concat(A.id, "\"><div class=\"fr-input-line\"><input id=\"fr-image-alt-layer-text-").concat(A.id, "\" type=\"text\" placeholder=\"").concat(A.language.translate("Alternative Text"), "\" tabIndex=\"1\"></div><div class=\"fr-action-buttons\"><button type=\"button\" class=\"fr-command fr-submit\" data-cmd=\"imageSetAlt\" tabIndex=\"2\" role=\"button\">").concat(A.language.translate("Update"), "</button></div></div>")
      },
          a = A.popups.create("image.alt", t);
      return A.$wp && A.events.$on(A.$wp, "scroll.image-alt", function () {
        C && A.popups.isVisible("image.alt") && j();
      }), a;
    }

    function Q() {
      var e = A.popups.get("image.size"),
          t = C.get(0).style.height ? C.get(0).style.height : "auto",
          a = C.get(0).style.width ? C.get(0).style.width : "auto";
      if (C) if (Se()) {
        var i = C.parent();
        i.get(0).style.width || (i = C.parent().parent()), e.find("input[name=\"width\"]").val(a).trigger("change"), e.find("input[name=\"height\"]").val(t).trigger("change");
      } else e.find("input[name=\"width\"]").val(a).trigger("change"), e.find("input[name=\"height\"]").val(t).trigger("change");
    }

    function Z() {
      var e = A.popups.get("image.size");
      e || (e = ee()), N(), A.popups.refresh("image.size"), A.popups.setContainer("image.size", A.$sc);
      var t = Ce(),
          a = t.outerWidth() / 2;
      Se() && (t = t.find(".fr-img-wrap"), a = C.outerWidth() / 2);
      var i = t.outerHeight();
      A.opts.iframe && Se() && (i = C ? C.outerHeight() : 0);
      var r = t.offset().left + a,
          n = t.offset().top + t.outerHeight();
      A.popups.show("image.size", r, n, i, !0);
    }

    function ee(e) {
      if (e) return A.popups.onRefresh("image.size", Q), !0;
      var t = {
        buttons: "<div class=\"fr-buttons fr-tabs\">".concat(A.button.buildList(A.opts.imageSizeButtons), "</div>"),
        size_layer: "<div class=\"fr-image-size-layer fr-layer fr-active\" id=\"fr-image-size-layer-".concat(A.id, "\"><div class=\"fr-image-group\"><div class=\"fr-input-line\"><input id=\"fr-image-size-layer-width-'").concat(A.id, "\" type=\"text\" name=\"width\" placeholder=\"").concat(A.language.translate("Width"), "\" tabIndex=\"1\"></div><div class=\"fr-input-line\"><input id=\"fr-image-size-layer-height").concat(A.id, "\" type=\"text\" name=\"height\" placeholder=\"").concat(A.language.translate("Height"), "\" tabIndex=\"1\"></div></div><div class=\"fr-action-buttons\"><button type=\"button\" class=\"fr-command fr-submit\" data-cmd=\"imageSetSize\" tabIndex=\"2\" role=\"button\">").concat(A.language.translate("Update"), "</button></div></div>")
      },
          a = A.popups.create("image.size", t);
      return A.$wp && A.events.$on(A.$wp, "scroll.image-size", function () {
        C && A.popups.isVisible("image.size") && Z();
      }), a;
    }

    function te(e, t, a, i) {
      return e.pageX = t, x.call(this || _global, e), e.pageX = e.pageX + a * Math.floor(Math.pow(1.1, i)), T.call(this || _global, e), $.call(this || _global, e), ++i;
    }

    function ae(e) {
      if (e = e || Ce(), A.opts.trackChangesEnabled && !A.helpers.isMobile() && (!e[0].parentNode || "SPAN" !== e[0].parentNode.tagName || !e[0].parentNode.hasAttribute("data-tracking"))) return A.track_changes.removeSpecialItem(e), A.popups.hideAll(), void ce(!0);
      e && !1 !== A.events.trigger("image.beforeRemove", [e]) && (A.popups.hideAll(), we(), ce(!0), A.undo.canDo() || A.undo.saveStep(), e.get(0) == A.el ? e.removeAttr("src") : (e.get(0).parentNode && "A" == e.get(0).parentNode.tagName ? (A.selection.setBefore(e.get(0).parentNode) || A.selection.setAfter(e.get(0).parentNode) || e.parent().after(Me.MARKERS), S(e.get(0).parentNode).remove()) : (A.selection.setBefore(e.get(0)) || A.selection.setAfter(e.get(0)) || e.after(Me.MARKERS), e.remove()), A.html.fillEmptyBlocks(), A.selection.restore()), A.undo.saveStep());
    }

    function ie(e) {
      var t = e.which;
      if (C && (t == Me.KEYCODE.BACKSPACE || t == Me.KEYCODE.DELETE)) return e.preventDefault(), e.stopPropagation(), ae(), !1;

      if (C && t == Me.KEYCODE.ESC) {
        var a = C;
        return ce(!0), A.selection.setAfter(a.get(0)), A.selection.restore(), e.preventDefault(), !1;
      }

      if (!C || t != Me.KEYCODE.ARROW_LEFT && t != Me.KEYCODE.ARROW_RIGHT) return C && t === Me.KEYCODE.TAB ? (e.preventDefault(), e.stopPropagation(), ce(!0), !1) : C && t != Me.KEYCODE.F10 && !A.keys.isBrowserAction(e) ? (e.preventDefault(), e.stopPropagation(), !1) : void 0;
      var i = C.get(0);
      return ce(!0), t == Me.KEYCODE.ARROW_LEFT ? A.selection.setBefore(i) : A.selection.setAfter(i), A.selection.restore(), e.preventDefault(), !1;
    }

    function re(e) {
      if (e && "IMG" == e.tagName) {
        if (A.node.hasClass(e, "fr-uploading") || A.node.hasClass(e, "fr-error") ? e.parentNode.removeChild(e) : A.node.hasClass(e, "fr-draggable") && e.classList.remove("fr-draggable"), e.parentNode && e.parentNode.parentNode && A.node.hasClass(e.parentNode.parentNode, "fr-img-caption")) {
          var t = e.parentNode.parentNode;
          t.removeAttribute("contenteditable"), t.removeAttribute("draggable"), t.classList.remove("fr-draggable");
          var a = e.nextSibling;
          a && a.removeAttribute("contenteditable");
        }
      } else if (e && e.nodeType == Node.ELEMENT_NODE) for (var i = e.querySelectorAll("img.fr-uploading, img.fr-error, img.fr-draggable"), r = 0; r < i.length; r++) re(i[r]);
    }

    function ne(e) {
      if (!1 === A.events.trigger("image.beforePasteUpload", [e])) return S(e).removeAttr("data-fr-image-pasted"), !1;
      C = S(e), D(), n(), ye(), k(), C.on("load", function () {
        var t = [];
        D(), S(A.popups.get("image.insert").get(0)).find("div.fr-active.fr-error").length < 1 && k(), S(this || _global).data("events").filter(function (e) {
          "load" === e[0] && t.push(e);
        }), t.length <= 1 && S(this || _global).off("load");
      });

      for (var t = S(e).attr("src").split(","), a = atob(t[1]), i = [], r = 0; r < a.length; r++) i.push(a.charCodeAt(r));

      H([A.browser.safari && !A.opts.imageUploadURL ? new Blob([new Uint8Array(i)], {
        type: t[0].replace(/data\:/g, "")
      }) : new Blob([new Uint8Array(i)], {
        type: t[0].replace(/data\:/g, "").replace(/;base64/g, "")
      })], C);
    }

    function oe() {
      A.opts.imagePaste ? A.$el.find("img[data-fr-image-pasted]").each(function (e, a) {
        if (A.opts.imagePasteProcess) {
          var t = A.opts.imageDefaultWidth;
          t && "auto" != t && (t += A.opts.imageResizeWithPercent ? "%" : "px"), S(a).css("width", t).removeClass("fr-dii fr-dib fr-fir fr-fil"), he(S(a), A.opts.imageDefaultDisplay, A.opts.imageDefaultAlign);
        }

        if (0 === a.src.indexOf("data:")) ne(a);else if (0 === a.src.indexOf("blob:") || 0 === a.src.indexOf("http") && A.opts.imageUploadRemoteUrls && A.opts.imageCORSProxy) {
          var i = new Image();
          i.crossOrigin = "Anonymous", i.onload = function () {
            var e = A.o_doc.createElement("CANVAS"),
                t = e.getContext("2d");
            e.height = (this || _global).naturalHeight, e.width = (this || _global).naturalWidth, t.drawImage(this || _global, 0, 0), setTimeout(function () {
              ne(a);
            }, 0), a.src = e.toDataURL(Me.IMAGE_TYPE);
          }, A.browser.msie && Me.IMAGE_ALLOW_REGEX.test(a.src) && (Me.IMAGE_TYPE = "image/" + a.src.split(Me.IMAGE_ALLOW_REGEX)[1]), i.src = (0 === a.src.indexOf("blob:") ? "" : "".concat(A.opts.imageCORSProxy, "/")) + a.src, 0 !== a.src.indexOf("blob:") && A.undo.saveStep();
        } else 0 !== a.src.indexOf("http") || 0 === a.src.indexOf("https://mail.google.com/mail") ? (A.selection.save(), S(a).remove(), A.selection.restore()) : S(a).removeAttr("data-fr-image-pasted");
      }) : A.$el.find("img[data-fr-image-pasted]").remove();
    }

    function se(e) {
      var t = e.target.result,
          a = A.opts.imageDefaultWidth;
      a && "auto" != a && (a += A.opts.imageResizeWithPercent ? "%" : "px"), A.undo.saveStep(), A.html.insert("<img data-fr-image-pasted=\"true\" src=\"".concat(t, "\"").concat(a ? " style=\"width: ".concat(a, ";\"") : "", ">"));
      var i = A.$el.find("img[data-fr-image-pasted=\"true\"]");
      i && he(i, A.opts.imageDefaultDisplay, A.opts.imageDefaultAlign), A.events.trigger("paste.after");
    }

    function le(e, t) {
      var a = new FileReader();
      a.onload = function i(e) {
        var t = A.opts.imageDefaultWidth;
        t && "auto" != t && (t += A.opts.imageResizeWithPercent ? "%" : "px"), A.html.insert("<img data-fr-image-pasted=\"true\" src=\"".concat(e, "\"").concat(t ? " style=\"width: ".concat(t, ";\"") : "", ">"));
        var a = A.$el.find("img[data-fr-image-pasted=\"true\"]");
        a && he(a, A.opts.imageDefaultDisplay, A.opts.imageDefaultAlign), A.events.trigger("paste.after");
      }(t), a.readAsDataURL(e, t);
    }

    function pe(e) {
      if (e && e.clipboardData && e.clipboardData.items) {
        var t = (e.clipboardData || window.clipboardData).getData("text/html") || "";
        A.browser.mozilla && ("" === t || /<!--StartFragment-->([ ])<!--EndFragment-->/.test(t)) && (t = A.win.localStorage.getItem("fr-copied-html"));
        var a = new DOMParser().parseFromString(t, "text/html").querySelector("img");

        if (a) {
          if (!a) return !1;
          var i = a.src,
              r = null;
          if (e.clipboardData.types && -1 != [].indexOf.call(e.clipboardData.types, "text/rtf") || e.clipboardData.getData("text/rtf")) r = e.clipboardData.items[0].getAsFile();else for (var n = 0; n < e.clipboardData.items.length; n++) if (r = e.clipboardData.items[n].getAsFile()) {
            Me.IMAGE_TYPE = r.type;
            break;
          }
          if (r) return le(r, i), !1;
        } else {
          var o = null;
          if (e.clipboardData.types && -1 != [].indexOf.call(e.clipboardData.types, "text/rtf") || e.clipboardData.getData("text/rtf")) o = e.clipboardData.items[0].getAsFile();else for (var s = 0; s < e.clipboardData.items.length && !(o = e.clipboardData.items[s].getAsFile()); s++);
          if (o) return function l(e) {
            var t = new FileReader();
            t.onload = se, t.readAsDataURL(e);
          }(o), !1;
        }
      }
    }

    function ge(e) {
      return e = e.replace(/<img /gi, "<img data-fr-image-pasted=\"true\" ");
    }

    function fe(e) {
      if ("false" == S(this || _global).parents("[contenteditable]").not(".fr-element").not(".fr-img-caption").not("body").first().attr("contenteditable")) return !0;
      if (e && "touchend" == e.type && a) return !0;
      if (e && A.edit.isDisabled()) return e.stopPropagation(), e.preventDefault(), !1;

      for (var t = 0; t < Me.INSTANCES.length; t++) Me.INSTANCES[t] != A && Me.INSTANCES[t].events.trigger("image.hideResizer");

      A.toolbar.disable(), e && (e.stopPropagation(), e.preventDefault()), A.helpers.isMobile() && (A.events.disableBlur(), A.$el.blur(), A.events.enableBlur()), A.opts.iframe && A.size.syncIframe(), C = S(this || _global), we(), D(), n(), A.browser.msie ? (A.popups.areVisible() && A.events.disableBlur(), A.win.getSelection && (A.win.getSelection().removeAllRanges(), A.win.getSelection().addRange(A.doc.createRange()))) : A.selection.clear(), A.helpers.isIOS() && (A.events.disableBlur(), A.$el.blur()), A.button.bulkRefresh(), A.events.trigger("video.hideResizer");
    }

    function ce(e) {
      C && (function t() {
        return de;
      }() || !0 === e) && (A.toolbar.enable(), l.removeClass("fr-active"), A.popups.hideAll(), C = null, ue(), p = null, g && g.hide());
    }

    r[i] = "Image cannot be loaded from the passed link.", r[c] = "No link in upload response.", r[d] = "Error during file upload.", r[m] = "Parsing response failed.", r[R] = "File is too large.", r[U] = "Image file type is invalid.", r[7] = "Files can be uploaded only to same domain in IE 8 and IE 9.";
    var de = !(r[8] = "Image file is corrupted.");

    function me() {
      de = !0;
    }

    function ue() {
      de = !1;
    }

    function he(e, t, a) {
      !A.opts.htmlUntouched && A.opts.useClasses ? (e.removeClass("fr-fil fr-fir fr-dib fr-dii"), a && e.addClass("fr-fi".concat(a[0])), t && e.addClass("fr-di".concat(t[0]))) : "inline" == t ? (e.css({
        display: "inline-block",
        verticalAlign: "bottom",
        margin: A.opts.imageDefaultMargin
      }), "center" == a ? e.css({
        "float": "none",
        marginBottom: "",
        marginTop: "",
        maxWidth: "calc(100% - ".concat(2 * A.opts.imageDefaultMargin, "px)"),
        textAlign: "center"
      }) : "left" == a ? e.css({
        "float": "left",
        marginLeft: 0,
        maxWidth: "calc(100% - ".concat(A.opts.imageDefaultMargin, "px)"),
        textAlign: "left"
      }) : e.css({
        "float": "right",
        marginRight: 0,
        maxWidth: "calc(100% - ".concat(A.opts.imageDefaultMargin, "px)"),
        textAlign: "right"
      })) : "block" == t && (e.css({
        display: "block",
        "float": "none",
        verticalAlign: "top",
        margin: "".concat(A.opts.imageDefaultMargin, "px auto"),
        textAlign: "center"
      }), "left" == a ? e.css({
        marginLeft: 0,
        textAlign: "left"
      }) : "right" == a && e.css({
        marginRight: 0,
        textAlign: "right"
      }));
    }

    function ve(e) {
      if (void 0 === e && (e = Ce()), e) {
        if (e.hasClass("fr-fil")) return "left";
        if (e.hasClass("fr-fir")) return "right";
        if (e.hasClass("fr-dib") || e.hasClass("fr-dii")) return "center";
        var t = e.css("float");

        if (e.css("float", "none"), "block" == e.css("display")) {
          if (e.css("float", ""), e.css("float") != t && e.css("float", t), 0 === parseInt(e.css("margin-left"), 10)) return "left";
          if (0 === parseInt(e.css("margin-right"), 10)) return "right";
        } else {
          if (e.css("float", ""), e.css("float") != t && e.css("float", t), "left" == e.css("float")) return "left";
          if ("right" == e.css("float")) return "right";
        }
      }

      return "center";
    }

    function be(e) {
      void 0 === e && (e = Ce());
      var t = e.css("float");
      return e.css("float", "none"), "block" == e.css("display") ? (e.css("float", ""), e.css("float") != t && e.css("float", t), "block") : (e.css("float", ""), e.css("float") != t && e.css("float", t), "inline");
    }

    function ye() {
      var e = A.popups.get("image.insert");
      e || (e = X()), A.popups.isVisible("image.insert") || (N(), A.popups.refresh("image.insert"), A.popups.setContainer("image.insert", A.$sc));
      var t = Ce(),
          a = t.outerWidth() / 2;
      Se() && (t = t.find(".fr-img-wrap"), a = C.outerWidth() / 2);
      var i = t.offset().left + a,
          r = t.outerHeight(!0);
      A.opts.iframe && Se() && (r = C ? C.outerHeight() : 0);
      var n = t.offset().top + t.outerHeight();
      A.popups.show("image.insert", i, n, r, !0);
    }

    function we() {
      if (C) {
        A.events.disableBlur(), A.selection.clear();
        var e = A.doc.createRange();
        e.selectNode(C.get(0)), A.browser.msie && e.collapse(!0), A.selection.get().addRange(e), A.events.enableBlur();
      }
    }

    function Ae() {
      return C;
    }

    function Ce() {
      return Se() ? C.parents(".fr-img-caption").first() : C;
    }

    function Se() {
      return !!C && 0 < C.parents(".fr-img-caption").length;
    }

    function Ee(e) {
      for (var t = document.createDocumentFragment(); e.firstChild;) {
        var a = e.removeChild(e.firstChild);
        t.appendChild(a);
      }

      e.parentNode.replaceChild(t, e);
    }

    return {
      _init: function Re() {
        var i;
        (function e() {
          A.events.$on(A.$el, A._mousedown, "IMG" == A.el.tagName ? null : "img:not([contenteditable=\"false\"])", function (e) {
            if ("false" == S(this || _global).parents("contenteditable").not(".fr-element").not(".fr-img-caption").not("body").first().attr("contenteditable")) return !0;
            A.helpers.isMobile() || A.selection.clear(), t = !0, A.popups.areVisible() && A.events.disableBlur(), A.browser.msie && (A.events.disableBlur(), A.$el.attr("contenteditable", !1)), A.draggable || "touchstart" == e.type || e.preventDefault(), e.stopPropagation();
          }), A.events.$on(A.$el, A._mousedown, ".fr-img-caption .fr-inner", function (e) {
            A.core.hasFocus() || A.events.focus(), e.stopPropagation();
          }), A.events.$on(A.$el, "paste", ".fr-img-caption .fr-inner", function (e) {
            !0 === A.opts.toolbarInline && (A.toolbar.hide(), e.stopPropagation());
          }), A.events.$on(A.$el, A._mouseup, "IMG" == A.el.tagName ? null : "img:not([contenteditable=\"false\"])", function (e) {
            if ("false" == S(this || _global).parents("contenteditable").not(".fr-element").not(".fr-img-caption").not("body").first().attr("contenteditable")) return !0;
            t && (t = !1, e.stopPropagation(), A.browser.msie && (A.$el.attr("contenteditable", !0), A.events.enableBlur()));
          }), A.events.on("keyup", function (e) {
            if (e.shiftKey && "" === A.selection.text().replace(/\n/g, "") && A.keys.isArrow(e.which)) {
              var t = A.selection.element(),
                  a = A.selection.endElement();
              t && "IMG" == t.tagName ? z(S(t)) : a && "IMG" == a.tagName && z(S(a));
            }
          }, !0), A.events.on("drop", V), A.events.on("element.beforeDrop", F), A.events.on("mousedown window.mousedown", me), A.events.on("window.touchmove", ue), A.events.on("mouseup window.mouseup", function () {
            if (C && !A.helpers.isMobile()) return ce(), !1;
            ue();
          }), A.events.on("touchstart touchend", function () {
            if (C && A.helpers.isMobile()) return ce(), !1;
            ue();
          }), A.events.on("commands.mousedown", function (e) {
            0 < e.parents(".fr-toolbar").length && ce();
          }), A.events.on("image.resizeEnd", function () {
            A.opts.iframe && A.size.syncIframe();
          }), A.events.on("blur image.hideResizer commands.undo commands.redo element.dropped", function () {
            ce(!(t = !1));
          }), A.events.on("modals.hide", function () {
            C && (we(), A.selection.clear());
          }), A.events.on("image.resizeEnd", function () {
            A.win.getSelection && z(C);
          }), A.opts.imageAddNewLine && A.events.on("image.inserted", function (e) {
            var t = e.get(0);

            for (t.nextSibling && "BR" === t.nextSibling.tagName && (t = t.nextSibling); t && !A.node.isElement(t);) t = A.node.isLastSibling(t) ? t.parentNode : null;

            A.node.isElement(t) && (A.opts.enter === Me.ENTER_BR ? e.after("<br>") : S(A.node.blockParent(e.get(0))).after("<".concat(A.html.defaultTag(), "><br></").concat(A.html.defaultTag(), ">")));
          });
        })(), "IMG" == A.el.tagName && A.$el.addClass("fr-view"), A.events.$on(A.$el, A.helpers.isMobile() && !A.helpers.isWindowsPhone() ? "touchend" : "click", "IMG" == A.el.tagName ? null : "img:not([contenteditable=\"false\"])", fe), A.helpers.isMobile() && (A.events.$on(A.$el, "touchstart", "IMG" == A.el.tagName ? null : "img:not([contenteditable=\"false\"])", function () {
          a = !1;
        }), A.events.$on(A.$el, "touchmove", function () {
          a = !0;
        })), A.$wp ? (A.events.on("window.keydown keydown", ie, !0), A.events.on("keyup", function (e) {
          if (C && e.which == Me.KEYCODE.ENTER) return !1;
        }, !0), A.events.$on(A.$el, "keydown", function () {
          var e = A.selection.element();
          (e.nodeType === Node.TEXT_NODE || "BR" == e.tagName && A.node.isLastSibling(e)) && (e = e.parentNode), A.node.hasClass(e, "fr-inner") || (A.node.hasClass(e, "fr-img-caption") || (e = S(e).parents(".fr-img-caption").get(0)), A.node.hasClass(e, "fr-img-caption") && (A.opts.trackChangesEnabled || S(e).after(Me.INVISIBLE_SPACE + Me.MARKERS), A.selection.restore()));
        })) : A.events.$on(A.$win, "keydown", ie), A.events.on("toolbar.esc", function () {
          if (C) {
            if (A.$wp) A.events.disableBlur(), A.events.focus();else {
              var e = C;
              ce(!0), A.selection.setAfter(e.get(0)), A.selection.restore();
            }
            return !1;
          }
        }, !0), A.events.on("toolbar.focusEditor", function () {
          if (C) return !1;
        }, !0), A.events.on("window.cut window.copy", function (e) {
          if (C && A.popups.isVisible("image.edit") && !A.popups.get("image.edit").find(":focus").length) {
            var t = Ce();
            Se() ? (t.before(Me.START_MARKER), t.after(Me.END_MARKER), A.selection.restore(), A.paste.saveCopiedText(t.get(0).outerHTML, t.text())) : (we(), A.paste.saveCopiedText(C.get(0).outerHTML, C.attr("alt"))), "copy" == e.type ? setTimeout(function () {
              z(C);
            }) : (ce(!0), A.undo.saveStep(), setTimeout(function () {
              A.undo.saveStep();
            }, 0));
          }
        }, !0), A.browser.msie && A.events.on("keydown", function (e) {
          if (!A.selection.isCollapsed() || !C) return !0;
          var t = e.which;
          t == Me.KEYCODE.C && A.keys.ctrlKey(e) ? A.events.trigger("window.copy") : t == Me.KEYCODE.X && A.keys.ctrlKey(e) && A.events.trigger("window.cut");
        }), A.events.$on(S(A.o_win), "keydown", function (e) {
          var t = e.which;
          if (C && t == Me.KEYCODE.BACKSPACE) return e.preventDefault(), !1;
        }), A.events.$on(A.$win, "keydown", function (e) {
          var t = e.which;
          C && C.hasClass("fr-uploading") && t == Me.KEYCODE.ESC && C.trigger("abortUpload");
        }), A.events.on("destroy", function () {
          C && C.hasClass("fr-uploading") && C.trigger("abortUpload");
        }), A.events.on("paste.before", pe), A.events.on("paste.beforeCleanup", ge), A.events.on("paste.after", oe), A.events.on("html.set", v), A.events.on("html.inserted", v), v(), A.events.on("destroy", function () {
          s = [];
        }), A.events.on("html.processGet", re), A.opts.imageOutputSize && A.events.on("html.beforeGet", function () {
          i = A.el.querySelectorAll("img");

          for (var e = 0; e < i.length; e++) {
            var t = i[e].style.width || S(i[e]).width(),
                a = i[e].style.height || S(i[e]).height();
            t && i[e].setAttribute("width", "".concat(t).replace(/px/, "")), a && i[e].setAttribute("height", "".concat(a).replace(/px/, ""));
          }
        }), A.opts.iframe && A.events.on("image.loaded", A.size.syncIframe), A.$wp && (b(), A.events.on("contentChanged", b)), A.events.$on(S(A.o_win), "orientationchange.image", function () {
          setTimeout(function () {
            C && z(C);
          }, 100);
        }), P(!0), X(!0), ee(!0), J(!0), A.events.on("node.remove", function (e) {
          if ("IMG" == e.get(0).tagName) return ae(e), !1;
        });
      },
      showInsertPopup: function Ue() {
        var e = A.$tb.find(".fr-command[data-cmd=\"insertImage\"]"),
            t = A.popups.get("image.insert");
        if (t || (t = X()), N(), !t.hasClass("fr-active")) if (A.popups.refresh("image.insert"), A.popups.setContainer("image.insert", A.$tb), e.isVisible()) {
          var a = A.button.getPosition(e),
              i = a.left,
              r = a.top;
          A.popups.show("image.insert", i, r, e.outerHeight());
        } else A.position.forSelection(t), A.popups.show("image.insert");
      },
      showLayer: function De(e) {
        var t,
            a,
            i = A.popups.get("image.insert");

        if (C || A.opts.toolbarInline) {
          if (C) {
            var r = Ce(),
                n = 0;
            Se() && (r = r.find(".fr-img-wrap"), n = C.outerWidth() / 2), a = r.offset().top + r.outerHeight(), t = r.offset().left + n;
          }
        } else {
          var o = A.$tb.find(".fr-command[data-cmd=\"insertImage\"]");
          t = o.offset().left, a = o.offset().top + (A.opts.toolbarBottom ? 10 : o.outerHeight() - 10);
        }

        !C && A.opts.toolbarInline && (a = i.offset().top - A.helpers.getPX(i.css("margin-top")), i.hasClass("fr-above") && (a += i.outerHeight())), i.find(".fr-layer").removeClass("fr-active"), i.find(".fr-".concat(e, "-layer")).addClass("fr-active"), A.popups.show("image.insert", t, a, C ? C.outerHeight() : 0, Se()), A.accessibility.focusPopup(i);
      },
      refreshUploadButton: function xe(e) {
        var t = A.popups.get("image.insert");
        t && t.find(".fr-image-upload-layer").hasClass("fr-active") && e.addClass("fr-active").attr("aria-pressed", !0);
      },
      refreshByURLButton: function Te(e) {
        var t = A.popups.get("image.insert");
        t && t.find(".fr-image-by-url-layer").hasClass("fr-active") && e.addClass("fr-active").attr("aria-pressed", !0);
      },
      upload: H,
      insertByURL: function $e() {
        var e = A.popups.get("image.insert").find(".fr-image-by-url-layer input");

        if (0 < e.val().length) {
          k(), B(A.language.translate("Loading image"));
          var t = e.val().trim();

          if (A.opts.imageUploadRemoteUrls && A.opts.imageCORSProxy && A.opts.imageUpload) {
            var a = new XMLHttpRequest();
            a.onload = function () {
              200 == (this || _global).status ? H([new Blob([(this || _global).response], {
                type: (this || _global).response.type || "image/png"
              })], C) : I(i);
            }, a.onerror = function () {
              _(t, !0, [], C);
            }, a.open("GET", "".concat(A.opts.imageCORSProxy, "/").concat(t), !0), a.responseType = "blob", a.send();
          } else _(t, !0, [], C);

          e.val(""), e.blur();
        }
      },
      align: function Ie(e) {
        var t = Ce();
        t.removeClass("fr-fir fr-fil"), !A.opts.htmlUntouched && A.opts.useClasses ? "left" == e ? t.addClass("fr-fil") : "right" == e && t.addClass("fr-fir") : he(t, be(), e), we(), D(), n(), A.selection.clear();
      },
      refreshAlign: function Pe(e) {
        C && e.find("> *").first().replaceWith(A.icon.create("image-align-".concat(ve())));
      },
      refreshAlignOnShow: function ke(e, t) {
        C && t.find(".fr-command[data-param1=\"".concat(ve(), "\"]")).addClass("fr-active").attr("aria-selected", !0);
      },
      display: function Ne(e) {
        var t = Ce();
        t.removeClass("fr-dii fr-dib"), !A.opts.htmlUntouched && A.opts.useClasses ? "inline" == e ? t.addClass("fr-dii") : "block" == e && t.addClass("fr-dib") : he(t, e, ve()), we(), D(), n(), A.selection.clear();
      },
      refreshDisplayOnShow: function Be(e, t) {
        C && t.find(".fr-command[data-param1=\"".concat(be(), "\"]")).addClass("fr-active").attr("aria-selected", !0);
      },
      replace: ye,
      back: function e() {
        C ? (A.events.disableBlur(), S(".fr-popup input:focus").blur(), z(C)) : (A.events.disableBlur(), A.selection.restore(), A.events.enableBlur(), A.popups.hide("image.insert"), A.toolbar.showInline());
      },
      get: Ae,
      getEl: Ce,
      insert: _,
      showProgressBar: k,
      remove: ae,
      hideProgressBar: N,
      applyStyle: function Oe(e, t, a) {
        if (void 0 === t && (t = A.opts.imageStyles), void 0 === a && (a = A.opts.imageMultipleStyles), !C) return !1;
        var i = Ce();

        if (!a) {
          var r = Object.keys(t);
          r.splice(r.indexOf(e), 1), i.removeClass(r.join(" "));
        }

        "object" == Ke(t[e]) ? (i.removeAttr("style"), i.css(t[e].style)) : (i.toggleClass(e), Se() && C.hasClass(e) && C.removeClass(e)), z(C);
      },
      showAltPopup: j,
      showSizePopup: Z,
      setAlt: function ze(e) {
        if (C) {
          var t = A.popups.get("image.alt");
          C.attr("alt", e || t.find("input").val() || ""), t.find("input:focus").blur(), z(C);
        }
      },
      setSize: function Le(e, t) {
        if (C) {
          var a = A.popups.get("image.size");
          e = e || a.find("input[name=\"width\"]").val() || "", t = t || a.find("input[name=\"height\"]").val() || "";
          var i = /^[\d]+((px)|%)*$/g;
          C.removeAttr("width").removeAttr("height"), e.match(i) ? C.css("width", e) : C.css("width", ""), t.match(i) ? C.css("height", t) : C.css("height", ""), Se() && (C.parents(".fr-img-caption").removeAttr("width").removeAttr("height"), e.match(i) ? C.parents(".fr-img-caption").css("width", e) : C.parents(".fr-img-caption").css("width", ""), t.match(i) ? C.parents(".fr-img-caption").css("height", t) : C.parents(".fr-img-caption").css("height", "")), a && a.find("input:focus").blur(), z(C);
        }
      },
      toggleCaption: function _e() {
        var e;

        if (C && !Se()) {
          (e = C).parent().is("a") && (e = C.parent());
          var t,
              a,
              i = C.parents("ul") && 0 < C.parents("ul").length ? C.parents("ul") : C.parents("ol") && 0 < C.parents("ol").length ? C.parents("ol") : [];

          if (0 < i.length) {
            var r = i.find("li").length,
                n = C.parents("li"),
                o = document.createElement("li");
            r - 1 === n.index() && (i.append(o), o.innerHTML = "&nbsp;");
          }

          e.attr("style") ? a = -1 < (t = e.attr("style").split(":")).indexOf("width") ? t[t.indexOf("width") + 1].replace(";", "") : "" : e.attr("width") && (a = e.attr("width"));
          var s = A.opts.imageResizeWithPercent ? (-1 < a.indexOf("px") ? null : a) || "100%" : C.width() + "px";
          e.wrap("<div class=\"fr-img-space-wrap\"><span " + (A.browser.mozilla ? "" : "contenteditable=\"false\"") + "class=\"fr-img-caption " + C.attr("class") + "\" style=\"" + (A.opts.useClasses ? "" : e.attr("style")) + "\" draggable=\"false\"></span></div>"), e.wrap("<span class=\"fr-img-wrap\"></span>"), C.after("<span class=\"fr-inner\"".concat(A.browser.mozilla ? "" : " contenteditable=\"true\"", ">").concat(Me.START_MARKER).concat(A.language.translate("Image Caption")).concat(Me.END_MARKER, "</span>")), C.parents(".fr-img-caption").css("width", s);
          var l = C.parents(".fr-img-space-wrap").length;
          1 == l ? Ee(document.querySelector(".fr-img-space-wrap")) : 1 < l && (Ee(document.querySelector(".fr-img-space-wrap")), Ee(document.querySelector(".fr-img-space-wrap2"))), ce(!0), A.selection.restore();
        } else {
          if (e = Ce(), C.insertBefore(e), null !== e[0].querySelector("a")) {
            for (var p, g = e[0].querySelector("a"), f = document.createElement("a"), c = 0, d = g.attributes, m = d.length; c < m; c++) p = d[c], f.setAttribute(p.nodeName, p.nodeValue);

            C.wrap(f);
          }

          C.attr("class", e.attr("class").replace("fr-img-caption", "")).attr("style", e.attr("style")), e.remove();
          var u = C.parents(".fr-img-space-wrap").length;
          1 == u ? Ee(document.querySelector(".fr-img-space-wrap")) : 1 < u && (Ee(document.querySelector(".fr-img-space-wrap")), Ee(document.querySelector(".fr-img-space-wrap2"))), z(C);
        }
      },
      hasCaption: Se,
      exitEdit: ce,
      edit: z
    };
  }, Me.DefineIcon("insertImage", {
    NAME: "image",
    SVG_KEY: "insertImage"
  }), Me.RegisterShortcut(Me.KEYCODE.P, "insertImage", null, "P"), Me.RegisterCommand("insertImage", {
    title: "Insert Image",
    undo: !1,
    focus: !0,
    refreshAfterCallback: !1,
    popup: !0,
    callback: function () {
      (this || _global).popups.isVisible("image.insert") ? ((this || _global).$el.find(".fr-marker").length && ((this || _global).events.disableBlur(), (this || _global).selection.restore()), (this || _global).popups.hide("image.insert")) : (this || _global).image.showInsertPopup();
    },
    plugin: "image"
  }), Me.DefineIcon("imageUpload", {
    NAME: "upload",
    SVG_KEY: "upload"
  }), Me.RegisterCommand("imageUpload", {
    title: "Upload Image",
    undo: !1,
    focus: !1,
    toggle: !0,
    callback: function () {
      (this || _global).image.showLayer("image-upload");
    },
    refresh: function (e) {
      (this || _global).image.refreshUploadButton(e);
    }
  }), Me.DefineIcon("imageByURL", {
    NAME: "link",
    SVG_KEY: "insertLink"
  }), Me.RegisterCommand("imageByURL", {
    title: "By URL",
    undo: !1,
    focus: !1,
    toggle: !0,
    callback: function () {
      (this || _global).image.showLayer("image-by-url");
    },
    refresh: function (e) {
      (this || _global).image.refreshByURLButton(e);
    }
  }), Me.RegisterCommand("imageInsertByURL", {
    title: "Insert Image",
    undo: !0,
    refreshAfterCallback: !1,
    callback: function () {
      (this || _global).image.insertByURL();
    },
    refresh: function (e) {
      (this || _global).image.get() ? e.text((this || _global).language.translate("Replace")) : e.text((this || _global).language.translate("Insert"));
    }
  }), Me.DefineIcon("imageDisplay", {
    NAME: "star",
    SVG_KEY: "imageDisplay"
  }), Me.RegisterCommand("imageDisplay", {
    title: "Display",
    type: "dropdown",
    options: {
      inline: "Inline",
      block: "Break Text"
    },
    callback: function (e, t) {
      (this || _global).image.display(t);
    },
    refresh: function (e) {
      (this || _global).opts.imageTextNear || e.addClass("fr-hidden");
    },
    refreshOnShow: function (e, t) {
      (this || _global).image.refreshDisplayOnShow(e, t);
    }
  }), Me.DefineIcon("image-align", {
    NAME: "align-left",
    SVG_KEY: "alignLeft"
  }), Me.DefineIcon("image-align-left", {
    NAME: "align-left",
    SVG_KEY: "alignLeft"
  }), Me.DefineIcon("image-align-right", {
    NAME: "align-right",
    SVG_KEY: "alignRight"
  }), Me.DefineIcon("image-align-center", {
    NAME: "align-justify",
    SVG_KEY: "alignCenter"
  }), Me.DefineIcon("imageAlign", {
    NAME: "align-justify",
    SVG_KEY: "alignJustify"
  }), Me.RegisterCommand("imageAlign", {
    type: "dropdown",
    title: "Align",
    options: {
      left: "Align Left",
      center: "None",
      right: "Align Right"
    },
    html: function () {
      var e = "<ul class=\"fr-dropdown-list\" role=\"presentation\">",
          t = Me.COMMANDS.imageAlign.options;

      for (var a in t) t.hasOwnProperty(a) && (e += "<li role=\"presentation\"><a class=\"fr-command fr-title\" tabIndex=\"-1\" role=\"option\" data-cmd=\"imageAlign\" data-param1=\"".concat(a, "\" title=\"").concat((this || _global).language.translate(t[a]), "\">").concat((this || _global).icon.create("image-align-".concat(a)), "<span class=\"fr-sr-only\">").concat((this || _global).language.translate(t[a]), "</span></a></li>"));

      return e += "</ul>";
    },
    callback: function (e, t) {
      (this || _global).image.align(t);
    },
    refresh: function (e) {
      (this || _global).image.refreshAlign(e);
    },
    refreshOnShow: function (e, t) {
      (this || _global).image.refreshAlignOnShow(e, t);
    }
  }), Me.DefineIcon("imageReplace", {
    NAME: "exchange",
    FA5NAME: "exchange-alt",
    SVG_KEY: "replaceImage"
  }), Me.RegisterCommand("imageReplace", {
    title: "Replace",
    undo: !1,
    focus: !1,
    popup: !0,
    refreshAfterCallback: !1,
    callback: function () {
      (this || _global).image.replace();
    }
  }), Me.DefineIcon("imageRemove", {
    NAME: "trash",
    SVG_KEY: "remove"
  }), Me.RegisterCommand("imageRemove", {
    title: "Remove",
    callback: function () {
      (this || _global).image.remove();
    }
  }), Me.DefineIcon("imageBack", {
    NAME: "arrow-left",
    SVG_KEY: "back"
  }), Me.RegisterCommand("imageBack", {
    title: "Back",
    undo: !1,
    focus: !1,
    back: !0,
    callback: function () {
      (this || _global).image.back();
    },
    refresh: function (e) {
      (this || _global).$;
      (this || _global).image.get() || (this || _global).opts.toolbarInline ? (e.removeClass("fr-hidden"), e.next(".fr-separator").removeClass("fr-hidden")) : (e.addClass("fr-hidden"), e.next(".fr-separator").addClass("fr-hidden"));
    }
  }), Me.RegisterCommand("imageDismissError", {
    title: "OK",
    undo: !1,
    callback: function () {
      (this || _global).image.hideProgressBar(!0);
    }
  }), Me.DefineIcon("imageStyle", {
    NAME: "magic",
    SVG_KEY: "imageClass"
  }), Me.RegisterCommand("imageStyle", {
    title: "Style",
    type: "dropdown",
    html: function () {
      var e = "<ul class=\"fr-dropdown-list\" role=\"presentation\">",
          t = (this || _global).opts.imageStyles;

      for (var a in t) if (t.hasOwnProperty(a)) {
        var i = t[a];
        "object" == Ke(i) && (i = i.title), e += "<li role=\"presentation\"><a class=\"fr-command\" tabIndex=\"-1\" role=\"option\" data-cmd=\"imageStyle\" data-param1=\"".concat(a, "\">").concat((this || _global).language.translate(i), "</a></li>");
      }

      return e += "</ul>";
    },
    callback: function (e, t) {
      (this || _global).image.applyStyle(t);
    },
    refreshOnShow: function (e, t) {
      var a = (this || _global).$,
          i = (this || _global).image.getEl();

      i && t.find(".fr-command").each(function () {
        var e = a(this || _global).data("param1"),
            t = i.hasClass(e);
        a(this || _global).toggleClass("fr-active", t).attr("aria-selected", t);
      });
    }
  }), Me.DefineIcon("imageAlt", {
    NAME: "info",
    SVG_KEY: "imageAltText"
  }), Me.RegisterCommand("imageAlt", {
    undo: !1,
    focus: !1,
    popup: !0,
    title: "Alternative Text",
    callback: function () {
      (this || _global).image.showAltPopup();
    }
  }), Me.RegisterCommand("imageSetAlt", {
    undo: !0,
    focus: !1,
    title: "Update",
    refreshAfterCallback: !1,
    callback: function () {
      (this || _global).image.setAlt();
    }
  }), Me.DefineIcon("imageSize", {
    NAME: "arrows-alt",
    SVG_KEY: "imageSize"
  }), Me.RegisterCommand("imageSize", {
    undo: !1,
    focus: !1,
    popup: !0,
    title: "Change Size",
    callback: function () {
      (this || _global).image.showSizePopup();
    }
  }), Me.RegisterCommand("imageSetSize", {
    undo: !0,
    focus: !1,
    title: "Update",
    refreshAfterCallback: !1,
    callback: function () {
      (this || _global).image.setSize();
    }
  }), Me.DefineIcon("imageCaption", {
    NAME: "commenting",
    FA5NAME: "comment-alt",
    SVG_KEY: "imageCaption"
  }), Me.RegisterCommand("imageCaption", {
    undo: !0,
    focus: !1,
    title: "Image Caption",
    refreshAfterCallback: !0,
    callback: function () {
      (this || _global).image.toggleCaption();
    },
    refresh: function (e) {
      (this || _global).image.get() && e.toggleClass("fr-active", (this || _global).image.hasCaption());
    }
  });
});
export default exports;